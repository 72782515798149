import React, { FC, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { GRAPH_FORM_TYPE, IScenarioDetails, NUMBER, SHARED_SCENARIO_PERMISSION } from '../../../constants';
import Slider from 'rc-slider';

import SimulationForm from '../../simlulation/SimulationForm';
import { ReactFlowProvider } from 'reactflow';
import { useAppSelector } from '../../../redux/hooks';

interface Props {
  formType: string
  simulationData?: any
  simulationHandler: () => void
  setSliderValue: React.Dispatch<React.SetStateAction<number>>
  scenarioDetails?: IScenarioDetails
  EVTraffic?: number
  selectedValue?: any

  dailyMiles?: number
  setDailyMiles?: any
  accessHC?: number
  setAccessHC?: any
  preferenceHC?: number
  setPreferenceHC?: any
  handleValueChange?: any
  setIsMonteCarloSimulation: any
}

const PowerGraphScratch: FC<Props> = ({
  formType,
  simulationHandler,
  setSliderValue,
  simulationData = {},
  scenarioDetails,
  EVTraffic,
  selectedValue,
  dailyMiles,
  setDailyMiles,
  accessHC,
  setAccessHC,
  preferenceHC,
  setPreferenceHC,
  handleValueChange,
  setIsMonteCarloSimulation
}) => {
  const getFieldValue = (key: string) => simulationData?.metrics?.find((metric: any) => metric.name === key)?.value;
  const getFieldTooltip = (key: string) => simulationData?.metrics?.find((metric: any) => metric.name === key)?.tooltip;

  const percentEv = simulationData?.metrics?.find((metric: any) => metric.name === 'pct_ev')?.value as number;
  const customStringValues = ['Mostly L1', 'Mostly L2', 'Equal'];


  const marksForHD = {
    1: {
      label: customStringValues[0]
      // style: { color: '#ff0000' },
    },
    2: {
      label: customStringValues[1]
      // style: { color: '#00ff00' },
    },
    3: {
      label: customStringValues[NUMBER.N2]
      // style: { color: '#0000ff' },
    }
  };
  const getFormJSX = () => {
    switch (formType) {
      case GRAPH_FORM_TYPE.POWER_HYDROGEN_FIELDS:
        return (
          <ReactFlowProvider>
            <SimulationForm setIsMonteCarloSimulation={setIsMonteCarloSimulation} />
          </ReactFlowProvider>
        );
      case GRAPH_FORM_TYPE.EV_FIELDS:
        return <div className="simulation-form fix-dublicate">
          <span className="graph-right-head">
            Simulation Variables
          </span>
          <div className="simulation-inner">
            <p className="slider-head graph-title-kw">EV % (VIO)</p>
            <div className="tooltip-container">
              <span className={`graph-value-kw ${!getFieldValue('pct_ev') && 'empty'}`}>{percentEv ? percentEv?.toFixed(NUMBER.N1) : '-'}%</span>
              <div className="tooltip-text">{getFieldTooltip('pct_ev')}</div>
            </div>
          </div>
          <div className="slider-wrap-inner ev-growth-inner mb-46">
            <span className="form-label">Avg daily distance (miles)</span>
            <div className="slider-progress-value-wrap">
              <div className="star-progress-bar">
                <Slider
                  min={NUMBER.N25}
                  max={NUMBER.N45}
                  defaultValue={dailyMiles}
                  value={dailyMiles}
                  step={NUMBER.N10}
                  onChange={(val) => setDailyMiles(val as number)}
                  marks={{
                    [NUMBER.N25]: NUMBER.N25.toString(),
                    [NUMBER.N35]: NUMBER.N35.toString(),
                    [NUMBER.N45]: NUMBER.N45.toString()
                  }}
                  disabled={scenarioDetails?.permission === SHARED_SCENARIO_PERMISSION.COLLABORATOR}
                />
              </div>
              <span className="slider-progress-value">
                {dailyMiles}
              </span>
            </div>
          </div>
          <div className="slider-wrap-inner ev-growth-inner mb-46">
            <span className="form-label">Access to Home Charging (%)</span>
            <div className="slider-progress-value-wrap">
              <div className="star-progress-bar">
                <Slider
                  min={NUMBER.N50}
                  max={NUMBER.N100}
                  defaultValue={accessHC}
                  value={accessHC}
                  step={NUMBER.N25}
                  onChange={(val) => setAccessHC(val as number)}
                  marks={{
                    [NUMBER.N50]: NUMBER.N50.toString(),
                    [NUMBER.N75]: NUMBER.N75.toString(),
                    [NUMBER.N100]: NUMBER.N100.toString()
                  }}
                  disabled={scenarioDetails?.permission === SHARED_SCENARIO_PERMISSION.COLLABORATOR}
                />
              </div>
              <span className="slider-progress-value">
                {accessHC}
              </span>
            </div>
          </div>
          <div className="slider-wrap-inner ev-growth-inner mb-46">
            <span className="form-label">Preference to Home Charging  (%)</span>
            <div className="slider-progress-value-wrap">
              <div className="star-progress-bar">
                <Slider
                  min={NUMBER.N60}
                  max={NUMBER.N100}
                  defaultValue={preferenceHC}
                  value={preferenceHC}
                  step={NUMBER.N20}
                  onChange={(val) => setPreferenceHC(val as number)}
                  disabled={scenarioDetails?.permission === SHARED_SCENARIO_PERMISSION.COLLABORATOR}
                  marks={{
                    [NUMBER.N60]: NUMBER.N60.toString(),
                    [NUMBER.N80]: NUMBER.N80.toString(),
                    [NUMBER.N100]: NUMBER.N100.toString()
                  }}
                />
              </div>
              <span className="slider-progress-value">
                {preferenceHC}
              </span>
            </div>
          </div>
          <div className="slider-wrap-inner ev-growth-inner mb-46">
            <span className="form-label">Home Power distribution</span>
            <div className="slider-progress-value-wrap">
              <div className="star-progress-bar">
                <Slider
                  min={NUMBER.N1}
                  max={NUMBER.N3}
                  defaultValue={selectedValue}
                  value={selectedValue}
                  step={NUMBER.N1}
                  onChange={handleValueChange}
                  marks={marksForHD}
                  disabled={scenarioDetails?.permission === SHARED_SCENARIO_PERMISSION.COLLABORATOR}
                />
              </div>
              <span className="slider-progress-value">
                {selectedValue}
              </span>
            </div>
          </div>
          <div className="slider-wrap-inner ev-growth-inner mb-46">
            <span className="form-label">EV Sales Multiplier</span>
            <div className="slider-progress-value-wrap">
              <div className="star-progress-bar">
                <Slider
                  min={NUMBER.N10}
                  max={NUMBER.N200}
                  defaultValue={EVTraffic}
                  value={EVTraffic}
                  step={NUMBER.N10}
                  onChange={(val) => setSliderValue(val as number)}
                  disabled={scenarioDetails?.permission === SHARED_SCENARIO_PERMISSION.COLLABORATOR}
                />
              </div>
              <span className="slider-progress-value">
                {EVTraffic}%
              </span>
            </div>
          </div>
        </div>;
    }
  };

  return (
    <div className="chart-right">
      <div className="chart-right-inner">
        <div className="slider-wrap">
          <div className="simulation-form-wrap">
            {getFormJSX()}
            {formType !== GRAPH_FORM_TYPE.POWER_HYDROGEN_FIELDS &&
              <Button disabled={scenarioDetails?.permission === SHARED_SCENARIO_PERMISSION.COLLABORATOR}
                className="primary simulate-map-btn w-100" onClick={simulationHandler}>Simulate</Button>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerGraphScratch;
