import { toast } from 'react-toastify';
import axios from 'axios';
import { MESSAGES } from '../constants/validationMessages';
import { AddressComponent, IComponentProperties, IEmailEntry, IError, IProerties, IScenarioDetails, IScenarioSettings, MapDataType, ScheduleData } from '../constants/interface';
import { NUMBER } from '../constants/numbers';
import {
  Droppable, EdgesLineType, FIELD_TYPE, FORMULA_TITLE, LABELS, MILE_IN_METER, PERMISSION_ORDER,
  SHARED_SCENARIO_PERMISSION, USER_PERMISSIONS, USER_ROLE, PROJECT_TYPE_LOGO,
  GeoThermalUnits,
  GEO_LOCATION_API_URL,
  disablePropertiesDueToShipScaling,
  SCHEDULE_TYPE,
  SCHEDULE_KEYS
} from '../constants';
import * as XLSX from 'xlsx';
import { Node } from 'reactflow';
import { IWorkbenchProps } from '../redux/workbenchSlice';
import { WORKBENCH } from './routeConstants';
import { IUserState } from '../redux/auth/authReducer';
import { IInvitedApproverList } from '../redux/services/requestApprovalApis';
import moment from 'moment';
import { dateFormateText, defaultDays } from './defaultData';
import { geoThermalCommonMarker } from '../components/shared/Graph/GraphUtils';

const titleCharLength = [NUMBER.N50, NUMBER.N45, NUMBER.N40, NUMBER.N35, NUMBER.N30, NUMBER.N25, NUMBER.N20, NUMBER.N15, NUMBER.N10, NUMBER.N5];
const titleFontSize = [NUMBER.N25, NUMBER.N28, NUMBER.N30, NUMBER.N32, NUMBER.N35, NUMBER.N35, NUMBER.N35, NUMBER.N35, NUMBER.N35, NUMBER.N35];
// This function checks if there is an error in a specific form field. It returns a Boolean value indicating whether or not there is an error.
export const hasFieldError = (errors: any, touched: any, fieldName: any) => {
  return Boolean(errors[fieldName] && touched[fieldName]);
};

export const handleCognitoError = (error: IError) => {
  if (error.code === 'NotAuthorizedException') {
    toast.error(error.message === 'User is disabled.' ? MESSAGES.USER_DEACTIVATED : MESSAGES.WRONG_CREDS);
  } else if (error.code === 'UsernameExistsException') {
    toast.error(MESSAGES.USER_EXIST);
  } else {
    toast.error(error.message);
  }
};

export function getLocalTime(utcTimeString: string) {
  return Date.parse(utcTimeString);
};

export const getInitials = (str: string) => {
  return str?.slice(NUMBER.N0, NUMBER.N1).toLocaleUpperCase();
};

export function toTitleCase(inputString: string | null) {
  return inputString
    ?.split(' ')
    ?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    ?.join(' ');
}
/**
  * function to handle font sizes if the title text is long
  * @param {string} str - The user_id in application/json
  * @param {number[]} sizes - array of font sizes in decending order
  * @param {number[]} breakLength - array of lengths in decending order where font size should change
  * @return -  A class name ending with sizes array
  */
export function handleFontSize(str: string, breakLength: number[], sizes: number[]) {
  const length = str?.length;
  let fontSize = '';
  for (let i = NUMBER.N0; i < breakLength.length; i++) {
    if (length >= breakLength[i]) {
      fontSize = `font-${sizes[i]}`;
      break;
    }
  }
  return fontSize;
}

export function dynamicFontSize(str: string, breakLength: number[], sizes: number[]) {
  const length = str.length;
  let fontSize = 0;
  for (let i = NUMBER.N0; i < breakLength.length; i++) {
    if (length >= breakLength[i]) {
      fontSize = sizes[i];
      break;
    }
  }
  return fontSize;
}

// eslint-disable-next-line complexity
export function calculateFontSize(scenarioName: string) {
  const textLength = scenarioName.length;
  const textWhiteSpaces = scenarioName.split(' ');
  if ((textLength > NUMBER.N45 && textLength <= NUMBER.N50) && textWhiteSpaces.length === NUMBER.N1) {
    return NUMBER.N12;
  } else if ((textLength > NUMBER.N40 && textLength <= NUMBER.N45) && textWhiteSpaces.length === NUMBER.N1) {
    return NUMBER.N13;
  }
  if ((textLength > NUMBER.N35 && textLength <= NUMBER.N40) && textWhiteSpaces.length === NUMBER.N1) {
    return NUMBER.N14;
  } else if ((textLength > NUMBER.N30 && textLength <= NUMBER.N35) && textWhiteSpaces.length === NUMBER.N1) {
    return NUMBER.N16;
  } else if ((textLength > NUMBER.N20 && textLength <= NUMBER.N30) && textWhiteSpaces.length === NUMBER.N1) {
    return NUMBER.N20;
  } else if ((textLength > NUMBER.N10 && textLength <= NUMBER.N20) && textWhiteSpaces.length === NUMBER.N1) {
    return NUMBER.N30;
  } else if (textWhiteSpaces.length > NUMBER.N1) {
    return dynamicFontSize(
      scenarioName,
      titleCharLength,
      titleFontSize
    );
  } else {
    return NUMBER.N30;
  }
};

export function setLocalStorageItem(key: string, value: object | string | number) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorageItem(key: string) {
  const item = localStorage.getItem(key) ?? '';
  return item ? JSON.parse(item) : '';
}

export const saveAccessTokenToStore = (data: any): void => {
  setLocalStorageItem('token', {
    CognitoUser: { signInUserSession: data?.signInUserSession },
    accessToken: data?.signInUserSession.accessToken?.jwtToken,
    idToken: data?.signInUserSession.idToken?.jwtToken,
    refreshToken: data?.signInUserSession.refreshToken?.token
  });
};

export const toDateFormat = (date: any) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options as Intl.DateTimeFormatOptions);
};

export const rand = (maxLimit: number) => {
  return Math.floor(Math.random() * maxLimit);
};

export const getId = (name: string) => `${name.replaceAll(' ', '_')}_${rand(NUMBER.N10000)}`;

export const removeDropEffect = () => {
  const droppableEle = document.getElementsByClassName(Droppable);
  droppableEle[0]?.classList?.remove(Droppable);
};

// function to return the an array of lentgh given with value (1 to length) like [1, 2, 3. ...]
export const createArrayWithLength = (length: number) => {
  return Array.from({ length }, (_, index) => index + NUMBER.N1);
};

// function to return the an array of lentgh given with value given like [{ wch: 25 }, { wch: val }, { wch: val }, { wch: val },. ...]
export const createArrayWithLengthAndValues = (length: number, val: number) => {
  return Array.from({ length }, (_, index) => ({ wch: index === NUMBER.N0 ? NUMBER.N25 : val }));
};

/**
 * Function to toggle between Edges lineType value
 */
export const toggleLineType = (lineType: string) => {
  const currentIndex = EdgesLineType.indexOf(lineType);
  // Toggle to the next value in the array
  lineType = EdgesLineType[(currentIndex + 1) % EdgesLineType.length];

  return lineType;
};

// funtion to return Heighest level of permission...
export const getHighestLevelPermission = (groups: string[]) => {
  const permissionOrder = PERMISSION_ORDER;

  for (const permission of permissionOrder) {
    if (groups?.includes(permission)) {
      return permission;
    }
  }
  // If user has no matching permission, return a default value or handle as needed
  return groups?.[NUMBER.N0];
};

export const getAuthHeaders = () => {
  const tokenData = JSON.parse(localStorage.getItem('token') ?? '{}');

  return {
    'content-Type': 'application/json',
    'Authorization': `Bearer ${tokenData.accessToken}`
  };
};

export const getAuthHeaderMultipart = () => {
  const tokenData = JSON.parse(localStorage.getItem('token') ?? '{}');

  return {
    'Authorization': `Bearer ${tokenData.accessToken}`
  };
};
/**
 * @param nodes List of workbench nodes...
 * @param nodeId selected node id
 * @returns returns true if the selected node id has layers or not...
 */
export const hasLayers = (nodes: Node[], nodeId: string) => {
  const layeredComponent = nodes.filter((node) => node.data?.parentNode === nodeId);
  return !!layeredComponent.length;
};

export const getProjectTypeLogo = (projectType: string): string => {
  return PROJECT_TYPE_LOGO[projectType] || '';
};

export const getUniqueArrayList = (arr: any[]) => {
  return Array.from(new Set(arr));
};

export function isArrayofObjects(arr: any[]) {
  return Array.isArray(arr) && arr.length > 0 && typeof arr[0] === 'object';
}

export function isValueWithSchedule(value: any): value is ScheduleData {
  return value && typeof value === 'object' && 'scheduleType' in value && 'scheduleData' in value;
}

export const getPropertyType = (ele: IProerties) => {
  let fieldType = FIELD_TYPE.TEXT;
  if (ele.formulaTitle === FORMULA_TITLE.TURBINE_HEIGHT || ele.scheduleOn) {
    fieldType = FIELD_TYPE.TEXT;
  } else {
    fieldType = ele.propertyType === FIELD_TYPE.NUMBER ? FIELD_TYPE.NUMBER : FIELD_TYPE.TEXT;
  }
  return fieldType;
};

export const getScenarioDetails = (scenario: IScenarioDetails) => {
  return {
    _id: scenario._id,
    projectID: scenario.projectID,
    projectName: scenario.projectName,
    name: scenario.name,
    description: scenario.description,
    createDate: scenario.createDate,
    updateDate: scenario.updateDate,
    userCreator: scenario.userCreator,
    permission: scenario.permission,
    subProject_Type: scenario.subProject_Type,
    id: scenario._id,
    approvalStatus: scenario.approvalStatus,
    BaselineStatus: scenario.BaselineStatus
  };
};

export const getWorkbenchURL = (scenarioId: string, type: string) => {
  return `${WORKBENCH}?scenario_id=${scenarioId}&type=${type}`;
};

const formatDate = (inputDate: any) => {
  const date = new Date(inputDate);
  const options = { month: 'short', day: 'numeric' };
  // @ts-expect-error datetime
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const getDateTime = (data: IScenarioDetails) => {
  if (data.updateDate) {
    return `Updated: ${formatDate(data.updateDate)}`;
  } else {
    return `Created: ${formatDate(data.createDate)}`;
  }
};

export const getWorkbenchPayload = (workbenchData: any) => {
  const updatedNodes = workbenchData.nodes.map((node: Node) => {
    return {
      ...node,
      data: {
        ...node.data,
        comments: []
      }
    };
  });
  return {
    theme: workbenchData.theme,
    id: workbenchData.scenarioDetails.id,
    name: workbenchData.scenarioDetails.name,
    exportJSON: {
      nodes: updatedNodes,
      edges: workbenchData.edges,
      viewPort: workbenchData.viewPort
    }
  };
};

export const getSimulationPayload = (isDashboard: boolean, workbenchData: IWorkbenchProps) => {
  return {
    scenario_id: workbenchData.scenarioDetails.id,
    data: {
      Dashboard: isDashboard,
      exportJSON: {
        nodes: isDashboard ? workbenchData.savedNodes : workbenchData.nodes, // if sim 2 then send savedNodes from store...
        edges: workbenchData.edges,
        viewPort: workbenchData.viewPort
      }
    }
  };
};

export const getWorkbenchEVPayload = (workbenchData: any) => {
  return {
    theme: workbenchData.theme,
    id: workbenchData.scenarioDetails.id,
    name: workbenchData.scenarioDetails.name,
    lat: workbenchData.lat,
    lng: workbenchData.lng,
    startYear: workbenchData.startYear,
    endYear: workbenchData.endYear,
    radius: workbenchData.radius,
    evTrafficProp: workbenchData.evTrafficProp,
    isDuplicate: false,
    nrel_avg_dvmt: workbenchData.nrel_avg_dvmt,
    nrel_pref_dist: workbenchData.nrel_pref_dist,
    nrel_home_access_dist: workbenchData.nrel_home_access_dist,
    nrel_home_power_dist: workbenchData.nrel_home_power_dist
  };
};

export const getEVWorkbenchPayload = (scenarioDetails: any, evSimulationData: any) => {
  return {
    theme: '',
    id: scenarioDetails.id,
    name: scenarioDetails.name,
    lat: evSimulationData.lat,
    lng: evSimulationData.lng,
    startYear: evSimulationData.start_year,
    endYear: evSimulationData.end_year,
    radius: evSimulationData.radius,
    evTrafficProp: scenarioDetails.evTrafficProp,
    isDuplicate: false,
    nrel_avg_dvmt: scenarioDetails.nrel_avg_dvmt,
    nrel_pref_dist: scenarioDetails.nrel_pref_dist,
    nrel_home_access_dist: scenarioDetails.nrel_home_access_dist,
    nrel_home_power_dist: scenarioDetails.nrel_home_power_dist
  };
};

export const getEVWorkbenchDuplicatePayload = (evSimulationData: any) => {
  return {
    lat: evSimulationData.lat,
    lng: evSimulationData.lng,
    startYear: evSimulationData.start_year,
    endYear: evSimulationData.end_year,
    radius: evSimulationData.radius,
    nrel_avg_dvmt: evSimulationData.nrel_avg_dvmt,
    nrel_pref_dist: evSimulationData.nrel_pref_dist,
    nrel_home_access_dist: evSimulationData.nrel_home_access_dis,
    nrel_home_power_dist: evSimulationData.nrel_home_power_dist,
    evTrafficProp: evSimulationData?.evTrafficProp
  };
};

// Function to reverse a JSON object
export function reverseObject(originalObj: any) {
  const reversedObj: any = {};
  const keys = Object.keys(originalObj).reverse();
  for (const key of keys) {
    reversedObj[key] = originalObj[key];
  }
  return reversedObj;
}

/**
  * @description function to get Array of objects from Array of Array considering first index as keys and second index as values and so on...
*/
export const getAOOfromAOA = (data: any[]) => {
  const resArray = [];
  for (let i = NUMBER.N1; i < data.length; i += NUMBER.N2) {
    const keys = data[i - NUMBER.N1] as string[];
    const values = data[i] as any[];
    const obj: any = {};

    keys.forEach((key, index) => {
      obj[key] = values[index];
    });

    resArray.push(obj);
  }
  return resArray;
};

/**
  * @description function to get Edges Array of objects from Array of Array considering first index as keys and other index as values...
*/
export const getEdgesAOOfromAOA = (data: any[]) => {
  const resArray = [];
  const keys = data[NUMBER.N0] as string[];
  for (let i = NUMBER.N1; i < data.length; i += NUMBER.N1) {
    const values = data[i] as any[];
    const obj: any = {};

    keys.forEach((key, index) => {
      obj[key] = values[index];
    });

    resArray.push(obj);
  }
  return resArray;
};

function createArrayWithIncrementalValues(length: number, startYear: number) {
  return ['Property Name', ...Array.from({ length }, (_, index) => `${startYear + index}`)];
}

export function getPropertyValue(value: any[] | string) {
  if (Array.isArray(value)) {
    const newVal = isArrayofObjects(value) ? getValuesArr(value) : [...value ?? []];
    value = newVal[NUMBER.N0];
  }
  return value;
}

function getValuesArr(propertyVal: any[]) {
  return propertyVal.map(obj => {
    return Object.values(obj)?.[0];
  });
}
/**
 * @param properties component parameters array
 * @returns the propertiesValues, propertiesDisplay name with unit, formulaTitle and properties input type
 */
export const getWorkbenchNodeData = (properties: IProerties[], settingsData: IScenarioSettings) => {
  const setPropertyValues: IComponentProperties = {};
  const propertiesNames: IComponentProperties = {};
  const propertiesValues: IComponentProperties = {};
  const scheduleProperty: IComponentProperties = {};
  const propertiesFormulaTitle: IComponentProperties = {};
  const propertiesType: IComponentProperties = {}; // properties type 'number/text'
  const units: IComponentProperties = {}; // unit of properties...
  const minMax: IComponentProperties = {}; // for validation in sim 2
  let listOfTurbines: any = [];

  if (properties?.length) {
    properties.forEach((prop: IProerties) => {
      const val = prop.propertyValue ?? prop.DefaultValue ?? '';
      const isScheduleProperty = prop.scheduleOn ? prop.schedule : prop.propertyValue;
      // if workbench settings is saved then pick location from settings data...
      if ((prop.formulaTitle === 'location' || prop.formulaTitle === 'abatement_location') && settingsData.latitude) {
        setPropertyValues[prop.formulaTitle] = `${settingsData.latitude}, ${settingsData.longitude}`;
      } else {
        setPropertyValues[prop.formulaTitle] = val;
      }
      propertiesNames[getPropertyNameWithUnit(prop)] = val;
      propertiesValues[prop.formulaTitle] = isScheduleProperty;
      propertiesFormulaTitle[prop.propertyName.trim()] = prop.formulaTitle;
      propertiesType[prop.formulaTitle] = getPropertyType(prop);
      units[prop.formulaTitle] = prop.unit ?? '';
      minMax[prop.formulaTitle] = { min: prop.Min, max: prop.Max };
      if (prop.list_of_turbines) {
        listOfTurbines = [...prop.list_of_turbines];
      }
      scheduleProperty[prop.formulaTitle] = prop.scheduleOn;
    });
  }
  return {
    setPropertyValues,
    propertiesNames,
    propertiesValues,
    propertiesFormulaTitle,
    propertiesType,
    units,
    minMax,
    scheduleProperty,
    listOfTurbines
  };
};

/**
 * @param nodes Nodes array data;
 * @returns transform the array of objects into a nested array structure, where each property in the objects is represented as a sub-array.
 */
export const getFormatedNodes = (nodes: any[], yearsCount: number, startYear: number) => {
  return nodes.map(obj => {
    const category = obj['Component Name'];
    const data = Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        const newVal = isArrayofObjects(value) ? value.map(obj => Object.values(obj)[0]) : [...value];
        return [key, ...(newVal.splice(0, yearsCount))];
      } else if (isValueWithSchedule(value)) {
        const schedule = value?.scheduleData[value?.scheduleType];
        // Extract the data for the given scheduleType (e.g., 'Annual')
        const scheduleDataValues = Object.values(schedule).join(', ');
        // Construct the result based on your requirement
        return [key, `${value?.scheduleType} : ${scheduleDataValues}`];
      } else {
        return [key, value];
      };
    });
    // add title and a blank row...
    data.unshift(['Property Name', 'Values'], ['', '']);
    return { category, data };
  });
};

/**
 * @param workbook : excel workbook sheet
 * @returns this function returns nodes array from imported excel workbook sheet
 */
export const getNodeDataFromExcel = (workbook: XLSX.WorkBook, settingsStartYear: number) => {
  const resArr: any[] = [];
  workbook.SheetNames.forEach((name: string) => {
    const resultObject: any = {};
    const nodesSheet = workbook.Sheets[name];
    const sheetArray: any = XLSX.utils.sheet_to_json(nodesSheet, { header: 1 });
    for (let i = NUMBER.N2; i < sheetArray.length; i++) {
      if (sheetArray[i].length > NUMBER.N2) {
        const key = sheetArray[i][NUMBER.N0];
        const value = sheetArray[i].slice(NUMBER.N1);
        resultObject[getPropertyName(key)] = value;
      } else {
        const [key, value] = sheetArray[i];
        // check if the value is of scheduleData as string...
        if (typeof value === 'string' && value?.includes(':')) {
          resultObject[getPropertyName(key)] = convertToScheduleData(value, settingsStartYear);
        } else {
          resultObject[getPropertyName(key)] = value;
        }
      }
    }
    resArr.push(resultObject);
  });
  return resArr;
};

/**
 * @param inputString schedule value in the form of string which we get from excel.
 * @returns funtion to retun the converted value in the form of Scheduledata
 */
function convertToScheduleData(inputString: string, settingsStartYear: number) {
  // Split the input string by ' : ' to separate the schedule type and the data
  try {
    const [type, dataString] = inputString.split(':');
    const scheduleType = toSentenceCase(type.trim());

    // check if schedule types is matched with imported excel data...
    if (!SCHEDULE_TYPE.includes(scheduleType)) {
      const errMsg = `Schedule type is not correct. Please use ${SCHEDULE_TYPE.join(', ')} only.`;
      throw errMsg;
    }

    // Convert the data string into an array of numbers
    const dataArray = dataString.split(',').map(item => item.trim());

    // Define the accumulator type
    const scheduleData: Record<string, string> = {};

    // Create an object where schedule type keys maps the dataArray...
    SCHEDULE_KEYS[scheduleType].forEach((key: any, index: any) => {
      const value = dataArray[index] ?? NUMBER.N0;

      scheduleData[key] = value;
    });

    // Return the final structured object
    return {
      scheduleType: scheduleType.trim(),
      scheduleData: {
        [scheduleType.trim()]: scheduleData
      }
    };
  } catch (error: any) {
    toast.error(error);
  }
}

export function toSentenceCase(str: string) {
  return str.charAt(NUMBER.N0).toUpperCase() + str.slice(NUMBER.N1).toLowerCase();
}

/**
 * @param property component property
 * @returns get property name with unit...
 */
export const getPropertyNameWithUnit = (property: IProerties) => {
  const unit = property.unit ? ` (${property.unit})` : '';
  return `${property.propertyName.trim()}${unit}`;
};

/**
 * @param property component property name
 * @returns get property name only by removing unit...
 */
export const getPropertyName = (propertyName: string) => {
  return `${propertyName?.split(' (')?.[0]?.trim()}`;
};

/**
 * @param propertyValue updated value from form...
 * @param arrValue 10 year span data...
 * @returns updated the form value in 10 year span data...
 */
export const getArrayPropertyValue = (propertyValue: any, arrValue: any[], yearValue: any) => {
  const newArray = [...arrValue];
  return newArray.map((item) => {
    if (item) {
      const year = Object.keys(item)[0];
      if (year === yearValue) {
        return { [year]: propertyValue };
      }
    }
    return item;
  });
};

export const formatNumberToLocale = (number: number, locale = 'en-US') => {
  const integerPart = Math.floor(number ?? NUMBER.N0);
  return integerPart.toLocaleString(locale);
};

export const metersToMiles = (meters: number) => {
  const miles = meters / MILE_IN_METER;
  return miles.toFixed(NUMBER.N2);
};

export const milesToMeters = (miles: number) => {
  const meters = miles * MILE_IN_METER;
  if (miles === NUMBER.N099) {
    return NUMBER.N1600;
  }
  return meters;
};

export const modifyScenarioDataForShare = (name: any, createdBy: any, id: any) => {
  return {
    Scenario_name: name,
    Created_by: createdBy,
    Scenario_id: id
  };
};

export const formatAndRoundOffNumber = (value: number, nullValueReplacementOption?: string) => {
  if (value) {
    if (Number.isInteger(value)) {
      return value.toLocaleString();
    } else {
      return value.toLocaleString('en-US', {
        maximumFractionDigits: NUMBER.N2,
        minimumFractionDigits: NUMBER.N2
      });
    }
  } else {
    return nullValueReplacementOption ?? NUMBER.N0;
  }
};

export const formatAndRoundOffNumberToEight = (value: number) => {
  if (value) {
    if (Number.isInteger(value)) {
      return value.toLocaleString();
    } else {
      return value.toLocaleString('en-US', {
        maximumFractionDigits: NUMBER.N8,
        minimumFractionDigits: NUMBER.N8
      });
    }
  } else {
    return NUMBER.N0;
  }
};

export const formatIntegerOnly = (value: number, nullValueReplacementOption?: string) => {
  if (value !== undefined) {
    return (Math.round(value)).toLocaleString('en-US');
  } else {
    return nullValueReplacementOption ?? NUMBER.N0;
  }
};

// Permission Helpers

export const getCreateProjectPermission = (userData: IUserState) => {
  if (userData.isDesiderAdmin as boolean || userData.permissions.includes(USER_PERMISSIONS.create_projects)) {
    return true;
  } else if (userData.isBpUser && (userData.user_role === USER_ROLE.CONSUMER || userData.user_role === USER_ROLE.SUPER_USER)) {
    return true;
  } else {
    return false;
  }
};

/**
 * @param isShared is scenarios is shared or not
 * @param userData logged in user data
 * @param BaselineStatus scenario baseline status
 * @returns True if user have create scenario permission then they will have permission to edit workbench...
 */
export const hasPermissionToChangeComponents = (isShared: boolean, userData: IUserState, BaselineStatus: boolean) => {
  const hasPermissionToCreateScenario = userData.permissions.includes(USER_PERMISSIONS.create_scenarios);
  let canChangeComponents = false;
  if (isShared) {
    canChangeComponents = false;
  } else {
    canChangeComponents = hasPermissionToCreateScenario && !BaselineStatus;
  }
  return canChangeComponents;
};

/**
 * @param sharedPermission scenario shared with permission
 * @param permissions logged in user permissions
 * @param BaselineStatus scenario baseline status
 * @returns True if Scenario is shared and shared permission is not collaborator and if isEditParamertersPermission is true and not base line scenario...
 */

export const hasPermissionToEditParamerters = (sharedPermission: string, permissions: string[], BaselineStatus: boolean) => {
  const isSharedScenario = !!sharedPermission;
  const isEditParamertersPermission = permissions.includes(USER_PERMISSIONS.change_parameters);
  const isCollaborator = sharedPermission === SHARED_SCENARIO_PERMISSION.COLLABORATOR;
  let canEditParameters = false;
  if (isSharedScenario) {
    canEditParameters = !(isCollaborator || BaselineStatus);
  } else {
    canEditParameters = isEditParamertersPermission && !BaselineStatus;
  }
  return canEditParameters;
};


/**
 * @param isShared is scenarios is shared or not
 * @param userData logged in user data
 * @param BaselineStatus scenario baseline status
 * @returns True if Scenario is shared and shared permission is not collaborator and if user has permission to import excel and scenario is not baseline...
 */
export const hasPermissionToImportExcel = (sharedPermission: string, userData: IUserState, BaselineStatus: boolean) => {
  const isSharedScenario = !!sharedPermission;
  const isCollaborator = sharedPermission === SHARED_SCENARIO_PERMISSION.COLLABORATOR;
  const hasImportExcelPermission = userData.permissions.includes(USER_PERMISSIONS.import_excel);
  let canImportExcel = false;
  if (isSharedScenario) {
    canImportExcel = !(isCollaborator || BaselineStatus);
  } else {
    canImportExcel = hasImportExcelPermission && !BaselineStatus;
  }
  return canImportExcel;
};

export const isAdmin = (userData: IUserState) => {
  if (userData.isDesiderAdmin && !userData.isBpUser) {
    return true;
  } else {
    return false;
  }
};

export const getApprovalStaus = (approvalStatus = '', approverList: IInvitedApproverList[]) => {
  if (approvalStatus === 'all_approvers_approved') {
    return 'Approved';
  } else if (approvalStatus === 'pending') {
    const pendingApprover = approverList.filter(l => l?.status === 'pending').length;
    const pendingCount = pendingApprover ? `(${pendingApprover})` : '';
    return `Approval Pending ${pendingCount}`;
  } else {
    return 'Request Approval';
  }
};

export function isEmailIdAvailable(emailId: string, emailList: IEmailEntry[] = []) {
  for (const entry of emailList) {
    if (entry.emailid === emailId) {
      return entry;
    }
  }
  return false;
}

export const lastActiveDays = (date: string) => {
  const dateObj = new Date(date);
  const now = new Date();
  const diff = now.getTime() - dateObj.getTime();
  const days = Math.floor(diff / (NUMBER.N1000 * NUMBER.N60 * NUMBER.N60 * NUMBER.N24));

  if (date === defaultDays.today || date === defaultDays.yesterday) {
    return date.toLowerCase();
  } else {
    return `${days} days ago`;
  };
};

export const statusTextTitle = (status: string) => {
  return status === LABELS.ACTIVE ? LABELS.DEACTIVATE_USER : LABELS.ACTIVATE_USER;
};

export const statusTextTitleCompany = (status: string) => {
  return status === LABELS.ACTIVE ? LABELS.DEACTIVATE_COMPANY : LABELS.ACTIVATE_COMPANY;
};

export const statusTextButton = (status: string) => {
  return status === LABELS.ACTIVE ? LABELS.YES_DEACTIVATE : LABELS.YES_ACTIVATE;
};

export const isActive = (status: string) => {
  return status === LABELS.ACTIVE;
};

export const toFormatDate = (date: any) => {
  const dateObj = moment(date);
  const now = moment();
  const diff = now.diff(dateObj, 'days');

  if (date === '-') {
    return 'N/A';
  }
  if (diff === NUMBER.N0) {
    return defaultDays.today;
  } else if (diff === NUMBER.N1) {
    return defaultDays.yesterday;
  } else {
    return dateObj.format(dateFormateText);
  }
};

export const manageRoleText = (tableType: string) => {
  return tableType === 'companies' ? 'Manage Company' : tableType === 'users' ? 'Manage User Role' : 'Edit Object';
};

export const seeDetailsText = (tableType: string) => {
  return tableType === 'objects' ? null : 'See Details';
};

export const manageDeleteText = (tableType: string) => {
  return tableType === 'companies' ? 'Delete Company' : tableType === 'users' ? 'Delete User' : 'Delete Object';
};

export const manageStatusText = (tableType: string, status: string) => {
  return tableType === 'companies' ? statusTextTitleCompany(status) : tableType === 'users' ? statusTextTitle(status) : null;
};

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const mapSelectionToArray = (selection: any): string[] => {
  return Object.keys(selection).reduce((acc: string[], parent) => {
    return acc.concat(selection[parent]);
  }, []);
};

export function mapKeys(data: MapDataType, apiData: string[]): Record<string, string[]> {
  const result: Record<string, string[]> = {};

  // Iterate over each key in apiData
  for (const key of apiData) {
    // Check each category in data
    for (const category in data) {
      // Check if the key exists in the current category
      const found = data[category].find(item => item.value === key);
      // If found, add it to the result
      if (found) {
        // If result doesn't have the current category yet, create it
        if (!result[category]) {
          result[category] = [];
        }
        // Add the key to the result under its corresponding category
        result[category].push(key);
        // Break the loop as we found the key
        break;
      }
    }
  }

  return result;
}

export const getValueWithUnit = (params: any, nullReplacer?: string) => {
  return (params?.value || params?.value === NUMBER.N0) ? `${formatAndRoundOffNumber(params?.value)}${GeoThermalUnits[params?.unit as string] ?? params?.unit}`
    : nullReplacer ?? '--';
};

export const getValueWithUnitFirst = (params: any, prefix?: string, nullReplacer?: string) => {
  return (params?.value || params?.value === NUMBER.N0)
    ? `${GeoThermalUnits[params?.unit as string] ?? params?.unit}${formatAndRoundOffNumber(params?.value)}${prefix ?? ''}` : nullReplacer ?? '--';
};

export const getGeoThermalGraphSeries = (data: string[][] | number[][], secondaryYAxis: string, scenarioName?: string) => {
  const headers = data[NUMBER.N0];
  const rows = data.slice(NUMBER.N1);

  return {
    years: rows.map(row => (+row[NUMBER.N0] - NUMBER.N1)),
    series: headers.slice(NUMBER.N1).map((header, index) => ({
      name: scenarioName ? `${header} | ${scenarioName}` : header,
      type: 'line',
      data: rows.map(row => row[index + NUMBER.N1]),
      yAxis: (header as string) === secondaryYAxis ? NUMBER.N1 : NUMBER.N0,
      marker: geoThermalCommonMarker
    }))
  };
};

export const customNoOptionsMessage = (msg: string) => {
  return msg;
};

export const formatNumberWithCommas = (num: any) => {
  if (num === undefined || num === null || num === 'NaN') {
    return '';
  };

  if (typeof num !== 'string') {
    num = num?.toString();
  }

  if (num?.startsWith('.')) {
    num = '0' + num;
  }

  const [integerPart, decimalPart] = num.split('.');

  const pattern = /(-?\d+)(\d{3})/;
  let formattedInteger = integerPart;
  while (pattern.test(formattedInteger)) {
    formattedInteger = formattedInteger.replace(pattern, '$1,$2');
  }

  return decimalPart !== undefined ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

export const parseFormattedNumber = (formattedNum: any) => {
  const parsed = formattedNum.replace(/,/g, '');
  return isNaN(parsed) ? '' : parsed;
};

export const keyPressHandle = (event: any) => {
  const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
  if (!/\d/.test(event.key) && event.key !== '.' && !allowedKeys.includes(event.key)) {
    event.preventDefault();
  } else if (event.key === '.' && event.target.value.includes('.')) {
    event.preventDefault();
  }
};

export function showKeysAndCount(obj: any) {
  const keys = Object.keys(obj);
  const firstKey = keys[0];
  const secondKey = keys[1];
  const remainingCount = keys.length - 2;

  return {
    key1: firstKey,
    key2: secondKey,
    remainingCount
  };
}

export function calculateStandardDeviation(data: any, mean: any) {
  if (data.length === 0) return 0;

  // Step 2: Subtract the mean from each data point and square the result
  const squaredDiffs = data.map(function (value: any) {
    const diff = value - mean;
    return diff * diff;
  });

  // Step 3: Calculate the average of these squared differences
  const averageSquaredDiff = squaredDiffs.reduce(function (sum: any, value: any) {
    return sum + value;
  }, 0) / squaredDiffs.length;

  // Step 4: Take the square root of this average
  return Math.sqrt(averageSquaredDiff).toFixed(2);
}

export const hasAtLeastOneKey = (obj: any) => Object.keys(obj).length > 0;

export function hasAtLeastOneCheckedItem(obj: any) {
  return Object.values(obj).some((item: any) => item.checked === true);
}

export const capitalizeAndRemoveUnderscore = (propertyName: string, preserveCapitalLetter?: boolean) => {
  // Replace underscores with spaces
  let formattedStr = propertyName.replace(/_/g, ' ');

  // Capitalize each word
  formattedStr = formattedStr.replace(/\w\S*/g, (txt: any) => {
    if (preserveCapitalLetter) {
      return txt.charAt(0).toUpperCase() + txt.slice(1);
    }
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  return formattedStr;
};

export const CCUSSim2ItemsNotToInclude = [
  'Grid', 'natural_gas_supplier'
];

export const getLocationName = async (lat: string | null | number, lng: string | null | number, filterType: string): Promise<string | null> => {
  const url = GEO_LOCATION_API_URL(lat, lng);

  try {
    const response = await axios.get(url);
    if (response.data.status === 'OK') {
      const results = response.data.results;
      if (results.length > 0) {
        const addressComponents: AddressComponent[] = results[0].address_components;
        const cityComponent = addressComponents.find((component: AddressComponent) =>
          component.types.includes(filterType)
        );
        return cityComponent ? cityComponent.long_name : 'City not found';
      }
    } else {
      throw new Error('Geocoding API error');
    }
  } catch (error) {
    return null;
  }
  return null;
};

export const truncateBigNumbers = (data: any) => {
  const value: any = data;
  if (value >= 1000000000) {
    return Math.floor((value / 1000000000)) + 'B'; // Billion
  } else if (value >= 1000000) {
    return Math.floor((value / 1000000)) + 'M'; // Million
  } else if (value >= 1000) {
    return Math.floor((value / 1000)) + 'K'; // Thousand
  }
  return value.toFixed(2);
};

export const checkToDisableOrNot = (component: string, propertyName: string) => {
  const hasComponent = disablePropertiesDueToShipScaling.some((obj: any) => component in obj && obj[component]?.includes(propertyName));
  if (hasComponent) {
    return true;
  }
  return false;
};
