import React from 'react';
import { formatAndRoundOffNumber, formatAndRoundOffNumberToEight, formatIntegerOnly } from '../../utils';
import { NUMBER } from '../../constants';

const SingalFinancialData = ({ item, selectedScenarioList, index, projectType }: any) => {
  return (
    <div className='ev-bp-table-sec map-view-table'>
      <div className='ev-bp-table-inner'>
        <div className='row'>
          <div className='col-md-12 varticle-border' key={`${item?.scenario_id}-${index}`}>
            <div className="bp-th">{item.scenario_name ?? selectedScenarioList[index].label}</div>
            <div className='td-inner-wrap'>
              <span className='td-label'>Total Capex</span>
              <span className='td-text td-value'>${formatAndRoundOffNumber(item?.Capex) ?? '0'}</span>
            </div>
            <div className='td-inner-wrap'>
              <span className='td-label'>Variable</span>
              <span className='td-text td-value'>${formatAndRoundOffNumber(item?.Opex) ?? '0'}</span>
            </div>
            <div className='td-inner-wrap'>
              <span className='td-label'>Total Period Cost</span>
              <span className='td-text td-value'>${formatAndRoundOffNumber(item?.Period_Value) ?? '0'}</span>
            </div>
            <div className='td-inner-wrap'>
              <span className='td-label'>Local Tax Credits</span>
              <span className='td-text td-value'>${formatIntegerOnly(item?.taxCredit, '-') ?? '-'}</span>
            </div>
            <div className='td-inner-wrap'>
              <span className='td-label'>Carbon Tax</span>
              <span className='td-text td-value'>${formatIntegerOnly(item?.carbonTax, '-') ?? '-'}</span>
            </div>
            {projectType === 'hydrogen' && <div className='td-inner-wrap'>
              <span className='td-label'>LCOH</span>
              <span className='td-text td-value'>${formatAndRoundOffNumber(item?.LCOH) ?? '0'}</span>
            </div>}
            {projectType === 'ccus' && <div className='td-inner-wrap'>
              <span className='td-label'>LCOC</span>
              <span className='td-text td-value'>${formatAndRoundOffNumber(item?.levelized_costs_of_gaseous_co2) ?? '0'}</span>
            </div>}
            <div className='td-inner-wrap'>
              <span className='td-label'>LCOE</span>
              <span className='td-text td-value'>${formatAndRoundOffNumber(item?.LCOE_Total) ?? '0'}</span>
            </div>
            <div className='td-inner-wrap'>
              <span className='td-label'>NPV</span>
              <span className='td-text td-value'>${formatAndRoundOffNumber(item?.NPV) ?? '0'}</span>
            </div>
            <div className='td-inner-wrap'>
              <span className='td-label'>IRR</span>
              <span className='td-text td-value'>{item?.IRR < NUMBER.N1 ? '-' : formatIntegerOnly(item?.IRR, '-')}</span>
            </div>
            <div className='td-inner-wrap'>
              <span className='td-label'>Emissions</span>
              <span className='td-text td-value'>{formatAndRoundOffNumberToEight(item?.['Carbon_Abatement_(MT)']) ?? '0'} mt</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingalFinancialData;
