import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiEndpoints, ICategoryList, ICommentData, IMapComment, IObjectList, IProerties, Methods, NUMBER } from '../../constants';
import { interceptor } from '../../utils/interceptor';
import { startFullLoading, stopFullLoading } from '../SpinnerSlice';

export interface IObjectListResponse {
  categoryList: ICategoryList[]
  data: IObjectList[]
}

export interface IPropertiesPayload {
  id: string // reactflow node id
  componentName: string
}

export interface IPropertiesResponse {
  data: IProerties[]
  node_id: string
  compoment_name: string
}

export interface IEditCommentPayload {
  id: string
  commentText: string
}

export const workbenchApis = createApi({
  reducerPath: 'workbenchApis',
  baseQuery: interceptor,
  tagTypes: ['Workbench', 'GetParameters', 'GetCommentComponent', 'GetMetricsData', 'GetMapCommentData'],
  endpoints: (builder) => ({
    // api to get component properties...
    getObjectList: builder.query<IObjectListResponse, void>({
      query: () => ({
        url: ApiEndpoints.GET_OBJECT_LIST,
        method: Methods.GET
      }),
      transformResponse: (res: IObjectList[]) => {
        // get the list of array of category name...
        const categoryList = res.map((r) => {
          return { value: r.category[0], label: r.category[0] };
        });
        // get the unique array list, remove dupliate category from list...
        const uniqeCategoryArr = categoryList.filter((item, index) => index === categoryList.findIndex((o) => item.value === o.value));

        // Find the index of the "Electric Power" object
        const electricPowerIndex = uniqeCategoryArr.findIndex(item => item.value === 'Electric Power');

        // If found, move it to the beginning of the array
        if (electricPowerIndex !== -1) {
          const electricPowerItem = uniqeCategoryArr.splice(electricPowerIndex, NUMBER.N1)[NUMBER.N0];
          uniqeCategoryArr.unshift(electricPowerItem);
        }

        return { categoryList: uniqeCategoryArr, data: res };
      },
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          dispatch(startFullLoading());
          await queryFulfilled;
          dispatch(stopFullLoading());
        } catch (error) {
          dispatch(stopFullLoading());
        }
      },
      providesTags: ['Workbench']
    }),
    // api to get component properties...
    getComponentProperties: builder.query<IPropertiesResponse, object>({
      query: (payload: IPropertiesPayload) => ({
        url: `${ApiEndpoints.GET_COMPONENT_PROPERTIES}?component_name=${payload.componentName}&scenario_id=${payload.id}`,
        method: Methods.GET
      }),
      transformResponse: (res: IProerties[], meta, arg: IPropertiesPayload) => {
        return { data: res, node_id: arg.id, compoment_name: arg.componentName };
      },
      providesTags: ['GetParameters']
    }),

    /**
     * @description  Mutation api to get component properties...
     */
    getComponentAllProperties: builder.mutation<IPropertiesResponse, object>({
      query: (payload: IPropertiesPayload) => ({
        url: `${ApiEndpoints.GET_COMPONENT_PROPERTIES}?component_name=${payload.componentName}&scenario_id=${payload.id}`,
        method: Methods.GET
      }),
      transformResponse: (res: IProerties[], meta, arg: IPropertiesPayload) => {
        return { data: res, node_id: arg.id, compoment_name: arg.componentName };
      }
    }),

    /**
     * @description api to save workbench Data...
     */
    saveWorkbenchData: builder.mutation<any, object>({
      query: (payload: any) => ({
        url: ApiEndpoints.SAVE_WORKBENCH_DATA,
        method: Methods.POST,
        body: payload
      })
    }),

    /**
     * @description api to save EV as Duplicate
     */
    saveWorkbenchEVAsDuplicate: builder.mutation<any, object>({
      query: (payload: any) => ({
        url: `${ApiEndpoints.EV_WORKBENCH_SAVE_AS_DUPLICATE}?scenario_id=${payload.scenario_id}`,
        method: Methods.POST,
        body: payload.data
      })
    }),

    /**
     * @description api to save Simulation Data...
     */
    saveEVSimulationData: builder.mutation<any, object>({
      query: (payload: any) => ({
        url: `${ApiEndpoints.EV_SIMULATION_SAVE_DATA}?scenario_id=${payload.scenarioId}`,
        method: Methods.POST,
        body: payload.simulationData
      })
    }),

    saveCommentData: builder.mutation<any, object>({
      query: (payload: any) => ({
        url: ApiEndpoints.SAVE_COMMENT_COMPONENT,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['GetCommentComponent']
    }),
    // api to get component comments...
    getCommentComponent: builder.query<any, object>({
      query: (payload: any) => ({
        url: `${ApiEndpoints.GET_COMMENT_COMPONENT}${payload.scenarioId}`,
        method: Methods.GET
      }),
      providesTags: ['GetCommentComponent']
    }),

    // Save Map Comments
    saveMapCommentData: builder.mutation<any, object>({
      query: (payload: any) => ({
        url: ApiEndpoints.SAVE_MAP_COMMENT,
        method: Methods.POST,
        body: payload
      }),
      invalidatesTags: ['GetMapCommentData']
    }),

    // Remove Workbench Comments
    removeWorkbenchComments: builder.mutation<any, string>({
      query: (id: string) => ({
        url: `${ApiEndpoints.DELETE_WORKBENCH_COMMENT}?comments_id=${id}`,
        method: Methods.POST
      }),
      invalidatesTags: ['GetCommentComponent']
    }),

    // Edit Workbench Comments
    editWorkbenchComments: builder.mutation<any, IEditCommentPayload>({
      query: (payload: IEditCommentPayload) => ({
        url: `${ApiEndpoints.EDIT_WORKBENCH_COMMENT}?comment_id=${payload.id}`,
        method: Methods.POST,
        body: { commentText: payload.commentText }
      }),
      invalidatesTags: ['GetCommentComponent']
    }),

    // api to get component comments...
    getMapComments: builder.query<any, object>({
      query: (payload: any) => ({
        url: `${ApiEndpoints.GET_MAP_COMMENT}${payload.scenarioId}`,
        method: Methods.GET
      }),

      transformResponse: (res: any, meta, arg: any) => {
        const groupedData: Record<string, IMapComment[]> = {};
        res.forEach((item: IMapComment) => {
          const commentId = item.commentid;
          if (!groupedData[commentId]) {
            groupedData[commentId] = [];
          }
          groupedData[commentId].push(item);
        });
        const convertedData: ICommentData[] = Object.keys(groupedData).map(
          (key) => {
            return {
              id: key,
              comments: groupedData[key]
            };
          }
        );

        return {
          data: res,
          mapComments: convertedData || []
        };
      },
      providesTags: ['GetMapCommentData']
    }),

    getMetricsData: builder.query<any, object>({
      query: (payload: any) => ({
        url: `${ApiEndpoints.GET_METRICS_DATA}?scenario_id=${payload.scenarioId}`,
        method: Methods.GET
      }),
      providesTags: ['GetMetricsData']
    }),
    /**
     * @description api to save get graph Data... It is for testing purpose it will be removed when actual api is provided
     */
    powerGraphData: builder.query<any, void>({
      query: () => ({
        url: 'https://djl3iqq9q0.execute-api.us-east-1.amazonaws.com/dev/get-plotting-data-by-sp-id/?subProjectID=64eda50ba65709667a2dc687&page=1&page_size=400',
        method: Methods.GET
      })
    }),

    // Remove Map Comments
    removeMapComments: builder.mutation<any, string>({
      query: (id: string) => ({
        url: `${ApiEndpoints.DELETE_MAP_COMMENT}?comments_id=${id}`,
        method: Methods.POST
      }),
      invalidatesTags: ['GetMapCommentData']
    }),

    // Edit Map Comments
    editMapComments: builder.mutation<any, IEditCommentPayload>({
      query: (payload: IEditCommentPayload) => ({
        url: `${ApiEndpoints.EDIT_MAP_COMMENT}?comment_id=${payload.id}`,
        method: Methods.POST,
        body: { commentText: payload.commentText }
      }),
      invalidatesTags: ['GetMapCommentData']
    })
  })
});

export const {
  useGetObjectListQuery,
  useGetComponentPropertiesQuery,
  useGetComponentAllPropertiesMutation,
  useSaveWorkbenchDataMutation,
  useSaveWorkbenchEVAsDuplicateMutation,
  useSaveEVSimulationDataMutation,
  useSaveCommentDataMutation,
  useGetCommentComponentQuery,
  useSaveMapCommentDataMutation,
  useGetMapCommentsQuery,
  useGetMetricsDataQuery,
  useLazyGetMetricsDataQuery,
  usePowerGraphDataQuery,
  useRemoveMapCommentsMutation,
  useEditMapCommentsMutation,
  useRemoveWorkbenchCommentsMutation,
  useEditWorkbenchCommentsMutation
} = workbenchApis;
