/* eslint-disable complexity */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { IProerties, NUMBER } from '../../constants';
import Slider from 'rc-slider';
import { formatNumberWithCommas, keyPressHandle, parseFormattedNumber } from '../../utils';

interface ISchedulingProps {
  show: boolean
  setShowHide: (show: boolean) => void
  onSave?: (prop: IProerties, value: any, scheduleType: string) => void
  componentData: any
  selectedProperty: any
}

function SchedulingModal(props: ISchedulingProps) {
  const { show, setShowHide, onSave, componentData, selectedProperty } = props;
  const { N0, N1, N2, N6 } = NUMBER;
  const { listOfSchedules, defaultScheduleValue, unit, constantUnit, schedule, propertyName, multiplierOn } = selectedProperty;
  const [userSelectedSchedule, setUserSelectedSchedule] = useState<'Hourly' | 'Monthly' | 'Annual' | 'Constant'>(defaultScheduleValue);
  const [monthlyValues, setMonthlyValues] = useState<Record<string, string>>();
  const [yearlyValues, setYearlyValues] = useState<Record<string, string>>({});
  const [dailyValues, setDailyValues] = useState<Record<string, string>>({});
  const [constantValue, setConstantValue] = useState<Record<string, string>>();
  const [universalValue, setUniversalValue] = useState<any>('');
  const [multiplierValues, setMultiplierValues] = useState({
    Hourly: N1,
    Monthly: N1,
    Annual: N1,
    Constant: N1
  });
  const [initialDailyValues, setInitialDailyValues] = useState<Record<string, string>>(dailyValues || {});
  const [initialMonthlyValues, setInitialMonthlyValues] = useState<Record<string, string>>(monthlyValues ?? {});
  const [initialYearlyValues, setInitialYearlyValues] = useState<Record<string, string>>(yearlyValues || {});
  const [initialConstantValue, setInitialConstantValue] = useState<Record<string, string>>(constantValue ?? {});
  const handleDailyChange = (label: string, value: string) => {
    if (Object.keys(initialDailyValues).indexOf(label) === N0 && multiplierOn) {
      updateValuesFromFirst(value);
    } else {
      setDailyValues((prevState) => ({
        ...prevState,
        [label]: value
      }));
    }
  };

  const handleMonthlyChange = (label: string, newValue: string) => {
    if (Object.keys(initialMonthlyValues).indexOf(label) === N0 && multiplierOn) {
      updateValuesFromFirst(newValue);
    } else {
      setMonthlyValues((prevState) => ({
        ...prevState,
        [label]: newValue
      }));
    }
  };

  const handleYearlyChange = (label: string, value: string) => {
    if (Object.keys(initialYearlyValues).indexOf(label) === N0 && multiplierOn) {
      updateValuesFromFirst(value);
    } else {
      setYearlyValues((prevState) => ({
        ...prevState,
        [label]: value
      }));
    }
  };


  const handleConstantChange = (newValue: string) => {
    setConstantValue({
      'constant demand': newValue
    });
  };

  const updateValue = () => {
    let scheduleDemand;
    if (listOfSchedules.length === 1) {
      scheduleDemand = { Annual: { ...yearlyValues } };
    } else if (listOfSchedules.length === 2) {
      scheduleDemand = { Annual: { ...yearlyValues }, Constant: { ...constantValue } };
    } else {
      scheduleDemand = { Hourly: { ...dailyValues }, Monthly: { ...monthlyValues }, Annual: { ...yearlyValues }, Constant: { ...constantValue } };
    }
    onSave?.(selectedProperty, scheduleDemand, userSelectedSchedule);
  };


  useEffect(() => {
    const componentSchedule = componentData[selectedProperty.formulaTitle];
    if (typeof componentSchedule === 'object') {
      setUserSelectedSchedule(componentSchedule?.scheduleType);
      setDailyValues(componentSchedule.scheduleData?.Hourly);
      setMonthlyValues(componentSchedule.scheduleData?.Monthly);
      setYearlyValues(componentSchedule.scheduleData?.Annual);
      setConstantValue(componentSchedule.scheduleData?.Constant);
      setInitialDailyValues(componentSchedule.scheduleData?.Hourly);
      setInitialMonthlyValues(componentSchedule.scheduleData?.Monthly);
      setInitialYearlyValues(componentSchedule.scheduleData?.Annual);
      setInitialConstantValue(componentSchedule.scheduleData?.Constant);
    } else {
      const dailySchedule = schedule.find((item: { scheduleType: string }) => item.scheduleType === 'Hourly')?.scheduleData;
      const monthlySchedule = schedule.find((item: { scheduleType: string }) => item.scheduleType === 'Monthly')?.scheduleData;
      const yearlySchedule = schedule.find((item: { scheduleType: string }) => item.scheduleType === 'Annual')?.scheduleData;
      const constantSchedule = schedule.find((item: { scheduleType: string }) => item.scheduleType === 'Constant')?.scheduleData;
      setDailyValues(dailySchedule);
      setMonthlyValues(monthlySchedule);
      setYearlyValues(yearlySchedule);
      setConstantValue(constantSchedule);
      setInitialDailyValues(dailySchedule);
      setInitialMonthlyValues(monthlySchedule);
      setInitialYearlyValues(yearlySchedule);
      setInitialConstantValue(constantSchedule);
    }
  }, [componentData, selectedProperty.formulaTitle, schedule]);

  const updateValuesFromFirst = (firstValue: string) => {
    const formattedFirstValue = firstValue.replace(/\.?0+$/, '');
    setUniversalValue('');
    if (userSelectedSchedule === 'Hourly') {
      const updatedDailyValues: Record<string, string> = {};
      Object.keys(initialDailyValues).forEach((key, index) => {
        if (index === 0) {
          updatedDailyValues[key] = formattedFirstValue;
        } else {
          const prevValue = parseFloat(Object.values(updatedDailyValues)[index - 1]);
          const updatedValue = prevValue * multiplierValues.Hourly;
          if (Number.isInteger(updatedValue)) {
            updatedDailyValues[key] = updatedValue.toString();
          } else {
            updatedDailyValues[key] = updatedValue.toFixed(2);
          }
        }
      });
      setDailyValues(updatedDailyValues);
      setInitialDailyValues(updatedDailyValues);
    } else if (userSelectedSchedule === 'Monthly') {
      const updatedMonthlyValues: Record<string, string> = {};
      Object.keys(initialMonthlyValues).forEach((key, index) => {
        if (index === 0) {
          updatedMonthlyValues[key] = formattedFirstValue;
        } else {
          const prevValue = parseFloat(Object.values(updatedMonthlyValues)[index - 1]);
          const updatedValue = prevValue * multiplierValues.Monthly;
          if (firstValue.endsWith('.')) {
            updatedMonthlyValues[key] = updatedValue + '.'.toString();
          }
          updatedMonthlyValues[key] = updatedValue.toString();
        }
      });
      setMonthlyValues(updatedMonthlyValues);
      setInitialMonthlyValues(updatedMonthlyValues);
    } else if (userSelectedSchedule === 'Annual') {
      const updatedYearlyValues: Record<string, string> = {};
      Object.keys(initialYearlyValues).forEach((key, index) => {
        if (index === 0) {
          updatedYearlyValues[key] = formattedFirstValue;
        } else {
          const prevValue = parseFloat(Object.values(updatedYearlyValues)[index - 1]) || 0;
          const updatedValue = prevValue * multiplierValues.Annual;
          updatedYearlyValues[key] = isNaN(updatedValue) ? '' : Number.isInteger(updatedValue) ? updatedValue.toString() : updatedValue.toFixed(2);
        }
      });
      setYearlyValues(updatedYearlyValues);
      setInitialYearlyValues(updatedYearlyValues);
    } else {
      const updatedConstantValue: Record<string, string> = {
        'constant demand': formattedFirstValue
      };
      setConstantValue(updatedConstantValue);
      setInitialConstantValue(updatedConstantValue);
    }
  };

  const updateWithUniversalValue = (val: number) => {
    const newValue = val.toString();
    const formattedNewValue = parseFloat(newValue).toFixed(2).replace(/\.?0+$/, '');

    if (userSelectedSchedule === 'Hourly') {
      const updatedDailyValues: Record<string, string> = {};
      Object.keys(initialDailyValues).forEach((key, index) => {
        updatedDailyValues[key] = formattedNewValue;
      });
      setDailyValues(updatedDailyValues);
      setInitialDailyValues(updatedDailyValues);
    } else if (userSelectedSchedule === 'Monthly') {
      const updatedMonthlyValues: Record<string, string> = {};
      Object.keys(initialMonthlyValues).forEach((key, index) => {
        updatedMonthlyValues[key] = formattedNewValue;
      });
      setMonthlyValues(updatedMonthlyValues);
      setInitialMonthlyValues(updatedMonthlyValues);
    } else if (userSelectedSchedule === 'Annual') {
      const updatedYearlyValues: Record<string, string> = {};
      Object.keys(initialYearlyValues).forEach((key, index) => {
        updatedYearlyValues[key] = formattedNewValue;
      });
      setYearlyValues(updatedYearlyValues);
      setInitialYearlyValues(updatedYearlyValues);
    } else {
      const updatedConstantValue: Record<string, string> = {
        'constant demand': formattedNewValue
      };
      setConstantValue(updatedConstantValue);
      setInitialConstantValue(updatedConstantValue);
    }
  };


  useEffect(() => {
    if (universalValue) {
      updateWithUniversalValue(universalValue);
      setInitialDailyValues(dailyValues);
      setInitialMonthlyValues(monthlyValues ?? {});
      setInitialYearlyValues(yearlyValues);
      setInitialConstantValue(constantValue ?? {});
    };
  }, [universalValue]);

  const handleUniversalConstantChange = (val: string) => {
    const numericValue = parseFloat(val);
    setUniversalValue(isNaN(numericValue) ? '' : numericValue);
    updateWithUniversalValue(numericValue);
    setMultiplierValues(prevValues => ({
      ...prevValues,
      [userSelectedSchedule]: 1
    }));
  };

  const handleRadioBtnChange = (val: 'Hourly' | 'Monthly' | 'Annual' | 'Constant') => {
    setUserSelectedSchedule(val);
    setMultiplierValues(prevValues => ({
      ...prevValues,
      [val]: prevValues[val]
    }));
    setUniversalValue('');
  };

  const handleMultiplierSlider = (val: number) => {
    setMultiplierValues(prevValues => ({
      ...prevValues,
      [userSelectedSchedule]: val
    }));
    computeValue(val);
    setUniversalValue('');
  };

  const computeValue = (value: any) => {
    const newValue = parseFloat(value);
    if (userSelectedSchedule === 'Hourly') {
      if (initialDailyValues) {
        const updatedDailyValues: Record<string, string> = {};
        let prevValue = 0;
        Object.keys(initialDailyValues).forEach((key, index) => {
          if (index === 0) {
            updatedDailyValues[key] = initialDailyValues[key];
            prevValue = parseFloat(initialDailyValues[key]);
          } else {
            const updatedValue = prevValue * newValue;
            if (Number.isInteger(updatedValue)) {
              updatedDailyValues[key] = updatedValue.toString();
            } else {
              updatedDailyValues[key] = updatedValue.toFixed(2);
            }
            prevValue = updatedValue;
          }
        });
        setDailyValues(updatedDailyValues);
      }
    } else if (userSelectedSchedule === 'Monthly') {
      if (initialMonthlyValues) {
        const updatedMonthlyValues: Record<string, string> = {};
        let prevValue = 0;
        Object.keys(initialMonthlyValues).forEach((key, index) => {
          if (index === 0) {
            updatedMonthlyValues[key] = initialMonthlyValues[key];
            prevValue = parseFloat(initialMonthlyValues[key]);
          } else {
            const updatedValue = prevValue * newValue;
            if (Number.isInteger(updatedValue)) {
              updatedMonthlyValues[key] = updatedValue.toString();
            } else {
              updatedMonthlyValues[key] = updatedValue.toFixed(2);
            }
            prevValue = updatedValue;
          }
        });
        setMonthlyValues(updatedMonthlyValues);
      }
    } else if (userSelectedSchedule === 'Annual') {
      if (initialYearlyValues) {
        const updatedYearlyValues: Record<string, string> = {};
        let prevValue = 0;
        Object.keys(initialYearlyValues).forEach((key, index) => {
          if (index === 0) {
            updatedYearlyValues[key] = initialYearlyValues[key];
            prevValue = parseFloat(initialYearlyValues[key]);
          } else {
            const updatedValue = prevValue * newValue;
            if (Number.isInteger(updatedValue)) {
              updatedYearlyValues[key] = updatedValue.toString();
            } else {
              updatedYearlyValues[key] = updatedValue.toFixed(2);
            }
            prevValue = updatedValue;
          }
        });
        setYearlyValues(updatedYearlyValues);
      }
    } else if (userSelectedSchedule === 'Constant') {
      if (initialConstantValue) {
        const updatedConstantValue: Record<string, string> = {};
        let prevValue = 0;
        Object.keys(initialConstantValue).forEach((key, index) => {
          if (index === 0) {
            updatedConstantValue[key] = initialConstantValue[key];
            prevValue = parseFloat(initialConstantValue[key]);
          } else {
            const updatedValue = prevValue * newValue;
            updatedConstantValue[key] = updatedValue.toFixed(2);
            prevValue = updatedValue;
          }
        });
        setConstantValue(updatedConstantValue);
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShowHide(false)}
        dialogClassName="modal-680 top-right-modal modal-dialog-centered"
        className="forget-modal setting-modal google-map-modal object-parameters-modal scheduling-modal"
      >
        <Modal.Header closeButton className="" onClick={() => setShowHide(false)}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="modal-head modal-head-medium modal-head-large-light">Set Schedule</h4>
          <Form className="object-parameter-form scheduling-form">
            <div className="scheduling-select-wrapper">
              {listOfSchedules.map((schedule: 'Hourly' | 'Monthly' | 'Annual' | 'Constant', index: number) => (
                <div className="scheduling-checkbox-wrapper" key={index}>
                  <Form.Check
                    type="radio"
                    id={`schedule-${index}`}
                    name="scheduleType"
                    value={schedule}
                    checked={userSelectedSchedule === schedule}
                    onChange={() => handleRadioBtnChange(schedule)}
                  />
                  <label htmlFor={`schedule-${index}`} className='schedule-checkbox-label'>{schedule}</label>
                </div>
              ))}
            </div>
            {multiplierOn && userSelectedSchedule !== 'Constant' && (<div className='schedule-universal-multiplier'>
              <div className='schedule-universal'>
                <label className="month-label"> Initial Value</label>
                <div className="input-with-unit">
                  <Form.Control type="number" min="0" value={universalValue} placeholder="Enter value" onChange={(e) => handleUniversalConstantChange(e.target.value)} />
                  <span className="scheduling-unit">{unit}</span>
                </div>
              </div>
              <div className='schedule-multiplier'>
                <div className="slider-wrap-inner ev-growth-inner mb-46">
                  <span className="form-label">Compounding Multiplier</span>
                  <div className="slider-progress-value-wrap">
                    <div className="star-progress-bar">
                      <Slider
                        min={0}
                        max={2}
                        defaultValue={0}
                        value={multiplierValues[userSelectedSchedule]}
                        marks={{ 0: 0, 1: 1, 2: 2 }}
                        step={0.1}
                        onChange={(val: any) => handleMultiplierSlider(val as number)}
                      />
                    </div>
                    <span className="slider-progress-value">{multiplierValues[userSelectedSchedule]}</span>
                  </div>
                </div>
              </div>
            </div>)}
            {monthlyValues && userSelectedSchedule === 'Monthly' && (
              <>
                <div className="scheduling-table-container">
                  <div className="scheduling-table-wrapper">
                    <table>
                      <tbody>
                        {[...Array(N6)].map((_, rowIndex) => (
                          <tr key={rowIndex}>
                            <td className="month-input">
                              <label className="month-label">{Object.keys(monthlyValues)[rowIndex].toUpperCase()}</label>
                              <div className="input-with-unit">
                                <Form.Control
                                  type="text"
                                  onKeyDown={keyPressHandle}
                                  value={formatNumberWithCommas(monthlyValues[Object.keys(monthlyValues)[rowIndex]]) ?? ''}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const parsedValue = parseFormattedNumber(inputValue); // Parse value without commas
                                    if (/^\d*\.?\d*$/.test(parsedValue)) {
                                      handleMonthlyChange(Object.keys(monthlyValues)[rowIndex], parsedValue);
                                      e.target.value = formatNumberWithCommas(inputValue); // Update displayed value with commas
                                    }
                                  }}
                                  placeholder="Enter values"
                                />
                                <span className="scheduling-unit">{unit}</span>
                              </div>
                            </td>
                            <td className="month-input">
                              <label className="month-label">{Object.keys(monthlyValues)[rowIndex + N6]?.toUpperCase()}</label>
                              <div className="input-with-unit">
                                <Form.Control
                                  type="text"
                                  onKeyDown={keyPressHandle}
                                  value={formatNumberWithCommas(monthlyValues[Object.keys(monthlyValues)[rowIndex + N6]]) ?? ''}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const parsedValue = parseFormattedNumber(inputValue); // Parse value without commas
                                    if (/^\d*\.?\d*$/.test(parsedValue)) {
                                      handleMonthlyChange(Object.keys(monthlyValues)[rowIndex + N6], parsedValue);
                                      e.target.value = formatNumberWithCommas(inputValue); // Update displayed value with commas
                                    }
                                  }}
                                  placeholder="Enter value"
                                />
                                <span className="scheduling-unit">{unit}</span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            {yearlyValues && userSelectedSchedule === 'Annual' && (
              <>
                <div className="scheduling-table-container">
                  <div className="scheduling-table-wrapper">
                    <table>
                      <tbody>
                        {Array.from({ length: Math.ceil(Object.keys(yearlyValues).length / N2) }).map((_, rowIndex) => (
                          <tr key={rowIndex}>
                            <td className="yearly-input">
                              <label className="yearly-label">{Object.keys(yearlyValues)[rowIndex]}</label>
                              <div className="input-with-unit">
                                <Form.Control
                                  type="text"
                                  onKeyDown={keyPressHandle}
                                  value={formatNumberWithCommas(yearlyValues[Object.keys(yearlyValues)[rowIndex]]) ?? ''}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const parsedValue = parseFormattedNumber(inputValue); // Parse value without commas
                                    if (/^\d*\.?\d*$/.test(parsedValue)) {
                                      handleYearlyChange(Object.keys(yearlyValues)[rowIndex], parsedValue);
                                      e.target.value = formatNumberWithCommas(inputValue); // Update displayed value with commas
                                    }
                                  }}
                                  placeholder="Enter value"
                                />
                                <span className="scheduling-unit">{unit}</span>
                              </div>
                            </td>
                            <td className="yearly-input">
                              <label className="yearly-label">{Object.keys(yearlyValues)[rowIndex + Math.ceil(Object.keys(yearlyValues).length / N2)]}</label>
                              <div className="input-with-unit">
                                <Form.Control
                                  type="text"
                                  onKeyDown={keyPressHandle}
                                  value={formatNumberWithCommas(yearlyValues[Object.keys(yearlyValues)[rowIndex + Math.ceil(Object.keys(yearlyValues).length / N2)]]) ?? ''}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const parsedValue = parseFormattedNumber(inputValue); // Parse value without commas
                                    if (/^\d*\.?\d*$/.test(parsedValue)) {
                                      handleYearlyChange(Object.keys(yearlyValues)[rowIndex + Math.ceil(Object.keys(yearlyValues).length / N2)], parsedValue);
                                      e.target.value = formatNumberWithCommas(inputValue); // Update displayed value with commas
                                    }
                                  }}
                                  placeholder="Enter value"
                                />
                                <span className="scheduling-unit">{unit}</span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
            {dailyValues && userSelectedSchedule === 'Hourly' && (
              <>
                <div className="scheduling-table-container">
                  <div className="scheduling-table-wrapper">
                    <table>
                      <tbody>
                        {[...Array(Math.ceil(Object.keys(dailyValues).length / N2))].map((_, rowIndex) => (
                          <tr key={rowIndex}>
                            <td className="daily-input">
                              <label className="daily-label">{Object.keys(dailyValues)[rowIndex]}</label>
                              <div className="input-with-unit">
                                <Form.Control
                                  type="text"
                                  onKeyDown={keyPressHandle}
                                  value={formatNumberWithCommas(dailyValues[Object.keys(dailyValues)[rowIndex]]) ?? ''}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const parsedValue = parseFormattedNumber(inputValue); // Parse value without commas
                                    if (/^\d*\.?\d*$/.test(parsedValue)) {
                                      handleDailyChange(Object.keys(dailyValues)[rowIndex], parsedValue);
                                      e.target.value = formatNumberWithCommas(inputValue); // Update displayed value with commas
                                    }
                                  }}
                                  placeholder="Enter value"
                                />
                                <span className="scheduling-unit">{unit}</span>
                              </div>
                            </td>
                            <td className="daily-input">
                              {Object.keys(dailyValues)[rowIndex + Math.ceil(Object.keys(dailyValues).length / N2)] && (
                                <label className="daily-label">{Object.keys(dailyValues)[rowIndex + Math.ceil(Object.keys(dailyValues).length / N2)]}</label>
                              )}
                              <div className="input-with-unit">
                                {Object.keys(dailyValues)[rowIndex + Math.ceil(Object.keys(dailyValues).length / N2)] && (
                                  <Form.Control
                                    type="text"
                                    onKeyDown={keyPressHandle}
                                    value={formatNumberWithCommas(dailyValues[Object.keys(dailyValues)[rowIndex + Math.ceil(Object.keys(dailyValues).length / N2)]]) ?? ''}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      const parsedValue = parseFormattedNumber(inputValue); // Parse value without commas
                                      if (/^\d*\.?\d*$/.test(parsedValue)) {
                                        handleDailyChange(Object.keys(dailyValues)[rowIndex + Math.ceil(Object.keys(dailyValues).length / N2)], parsedValue);
                                        e.target.value = formatNumberWithCommas(inputValue); // Update displayed value with commas
                                      }
                                    }}
                                    placeholder="Enter value"
                                  />
                                )}
                                <span className="scheduling-unit">{unit}</span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
            {userSelectedSchedule === 'Constant' && (
              <>
                <div className="scheduling-table-container">
                  <div className="scheduling-table-wrapper">
                    <table>
                      <tbody>
                        <tr >
                          <td className="constant-input">
                            <label className="constant-label">Constant</label>
                            <div className="input-with-unit">
                              <Form.Control
                                type="text"
                                value={constantValue?.['constant demand'] !== undefined ? formatNumberWithCommas(constantValue['constant demand']) : ''}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  const parsedValue = parseFormattedNumber(inputValue); // Parse value without commas
                                  if (/^\d*\.?\d*$/.test(parsedValue)) {
                                    handleConstantChange(parsedValue);
                                    e.target.value = formatNumberWithCommas(inputValue); // Update displayed value with commas
                                  }
                                }}
                                placeholder="Enter value"
                                onKeyDown={keyPressHandle}
                              />
                              <span className="scheduling-unit">{constantUnit ?? unit}</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
            <Modal.Footer>
              <Button className="btn-no-outline" onClick={() => setShowHide(false)}>
                Cancel
              </Button>
              <Button className="primary" onClick={updateValue}>Save Changes</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SchedulingModal;
